import React from "react";
import './css/page.css';
import './css/about.css';

function About() {
    return (
        <div id="about-body" className="page-body">
            {/* <div id="about-avatar">
                <img id="about-avatarimg" src={require('../assets/小靜素材庫/011-小靜&水滴.jpg')} alt="Lost" />
                <p>小靜しずか</p>
            </div> */}
            <div className="about-div">
                <div className="about-textdiv" >
                    <h1>基本資料</h1>
                    <p>
                        <ul>
                            <li>年齡：20</li>
                            <li>生日：01/28</li>
                            <li>食物：咖哩</li>
                            <li>興趣：唱歌畫畫遊戲</li>
                            <li>遊戲：特戰為主，偶爾嘗試其他遊戲</li>
                            <li>目前有正職，為非全職套皮實況主</li>
                            <li>請勿暈船，我只對妹子有差別待遇</li>
                            <li>聯動邀約💌：xiaojean0128@gmail.com</li>
                        </ul>
                    </p>
                </div>
            </div>
            <div className="about-div">
                <div className="about-textdiv">
                    <h1>里程碑</h1>
                    <p>
                        <ul>
                            <li>2022.09.13 開始實況✓</li>
                            <li>2022.09.22 實況盟友✓</li>
                            <li>2022.09.26 100追隨✓</li>
                            <li>2023.02.08 1000追隨✓</li>
                            <li>2023.11.27 1000訂閱✓特別感謝惜竹♥</li>
                            <li>2024.01.28 V皮初配信/車車16級</li>
                            <li>2024.07.30 合作夥伴✓</li>
                        </ul>
                    </p>
                </div>
            </div>

            <div className="about-div">
                <div className="about-textdiv">
                    <h1>繪師&素材</h1>
                    <p>
                        <ul>
                            <li>
                                V皮繪製：
                                <a href="https://twitter.com/fuwafuwafumi" target="_blank" rel="noreferrer">
                                    透明尾巴
                                </a>
                            </li>
                            <li>
                                V皮建模：
                                <a href="https://twitter.com/HuwaDuck" target="_blank" rel="noreferrer">
                                    步羽呼哇
                                </a>
                            </li>
                            <li>
                                頭像：
                                <a href="https://twitter.com/tongkong080" target="_blank" rel="noreferrer">
                                    同空
                                </a>
                            </li>
                            <li>
                                封面：
                                <a href="https://x.com/chan100577_02?s=21" target="_blank" rel="noreferrer">
                                    小千
                                </a>
                            </li>
                            <li>
                                關於我：
                                <a href="https://twitter.com/ChingChingUwU" target="_blank" rel="noreferrer">
                                    凌澄
                                </a>
                            </li>
                            <li>
                                離線圖片：
                                <a href="https://twitter.com/ma_ho_sho_jo_" target="_blank" rel="noreferrer">
                                    燒酒
                                </a>
                            </li>
                            <li>
                                斗內跑馬燈：
                                <a href="https://twitter.com/sixleafs" target="_blank" rel="noreferrer">
                                    六葉
                                </a>
                            </li>
                            <li>純音樂時的BGM：
                                <a href="https://www.youtube.com/watch?v=d6c7vSgkbtc&list=LL&index=18&t=2954s&ab_channel=zukisuzukiBGM"
                                    target="_blank" rel="noreferrer">
                                    YT
                                </a>
                            </li>
                        </ul>
                    </p>
                </div>
            </div>

        </div >
    )
}

export default About;