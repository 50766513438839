import React from "react";
import './css/page.css';
import './css/commision.css';

function Commision() {
    return (
        <div id="commision-body" className="page-body">
            <p>
                這裡是委託繪圖的頁面!<br />
                還在設計中~尚未開放~敬請期待!
            </p>
        </div>
    )
}

export default Commision;