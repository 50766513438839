import React from "react"
import Header from './components/screens/header';
import './components/screens/css/index.css';
import About from './pages/About';
import Commision from './pages/Commision';
import Home from './pages/Home';
import Merch from './pages/Merch';
import Rule from './pages/Rule';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Foooter from "./components/screens/footer";

function App() {
  return (
    <div className="index-body" >
      <div id="index-Main">
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Merch" element={<Merch />} />
            <Route path="/About" element={<About />} />
            <Route path="/Rule" element={<Rule />} />
            <Route path="/Commision" element={<Commision />} />
          </Routes>
          <Foooter />
        </Router>
      </div>
    </div>
  );
}

export default App;