import React from "react"
import './css/footer.css'

function Foooter() {
    return (
        <div id="footer-body">
            <div>
                <a href="https://www.twitch.tv/xiaotian1101" target="_blank" rel="noreferrer"><img src={require('../../assets/img/penguin-circle.png')} alt="Lost" /></a>
                <p>
                    CopyRight © 2024 . All  Contents are Designed By 嘯天
                </p>
            </div>
        </div>
    );
}

export default Foooter;