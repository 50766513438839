import React from "react";
import './css/page.css';
import './css/home.css';
import { ReactTyped } from "react-typed";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad } from '@fortawesome/free-solid-svg-icons';
import { faMusic } from '@fortawesome/free-solid-svg-icons';
import { faPalette } from '@fortawesome/free-solid-svg-icons';



function Home() {
    return (
        <div id="home-body" className="page-body">
            <div id="div-banner" >
                <div className="banner-text">
                    <ReactTyped
                        strings={["歡迎加入心結幫 ♡"]}
                        typeSpeed={40}
                        showCursor={false}
                        startDelay={0}
                    />
                    <br />
                    <ReactTyped
                        strings={["我喜歡玩遊戲、畫畫、唱歌 ♡"]}
                        typeSpeed={40}
                        showCursor={false}
                        startDelay={1500} // 根据第一行的长度和速度来调整
                    />
                    <br />
                    <ReactTyped
                        strings={["簡單的心願就是希望能在直播的時間讓你們感到快樂 ♡ "]}
                        typeSpeed={40}
                        showCursor={false}
                        startDelay={2500} // 根据前两行的长度和速度来调整
                    />
                </div>
            </div>
            <div id="introduce">
                <ul id="home-icon" type="none">
                    <li>
                        <FontAwesomeIcon className="FontAwesomeIcon" size="xl" icon={faGamepad} />
                        遊戲同樂
                    </li>
                    <li>
                        <FontAwesomeIcon className="FontAwesomeIcon" size="xl" icon={faMusic} />
                        不定時歌回
                    </li>
                    <li>
                        <FontAwesomeIcon className="FontAwesomeIcon" size="xl" icon={faPalette} />
                        委託繪圖
                    </li>
                </ul>
                <div id="introduce-img">
                    <img src={require('../assets/小靜素材庫/E.無分類/008-自我介紹-ver3.jpg')} alt="Lost" />
                    <span>
                        <a href="https://www.youtube.com/watch?v=-34xIBbxBn8" target="_blank" rel="noreferrer"
                        >《點擊前往V皮初配信》</a>
                    </span>
                    <ul id="social-link" type="none">
                        <li>
                            <a href="https://discord.gg/wyCzz4MJnh" target="_blank" rel="noreferrer">
                                <img src={require('../assets/小靜素材庫/A.社群網站的icon/Discord-white.png')} alt="Lost" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/xiaojean0128/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0" target="_blank" rel="noreferrer">
                                <img src={require('../assets/小靜素材庫/A.社群網站的icon/Facebook-white.png')} alt="Lost" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/xiaojean.0128?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noreferrer">
                                <img src={require('../assets/小靜素材庫/A.社群網站的icon/Instagram-white.png')} alt="Lost" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.twitch.tv/xiaojean0128" target="_blank" rel="noreferrer">
                                <img src={require('../assets/小靜素材庫/A.社群網站的icon/Twitch-white.png')} alt="Lost" />
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/xiaojean0128" target="_blank" rel="noreferrer">
                                <img src={require('../assets/小靜素材庫/A.社群網站的icon/Twitter-white.png')} alt="Lost" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/@xiaojean0128" target="_blank" rel="noreferrer">
                                <img src={require('../assets/小靜素材庫/A.社群網站的icon/Youtube-white.png')} alt="Lost" />
                            </a>
                        </li>
                        <li>
                            <a href="https://reurl.cc/zrE8L7" target="_blank" rel="noreferrer">
                                <img src={require('../assets/小靜素材庫/A.社群網站的icon/Marshmallow-white.png')} alt="Lost" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div id="donate">
                <h1>斗內</h1>
                <div>
                    <ul>
                        <li>喜歡我想支持我的話可以斗內我喔~</li>
                        <li>斗內的錢會拿來升級設備及委託圖</li>
                        <li>想斗內的話請先讓自己吃飽並量力而為</li>
                        <li>有斗內的記得告訴我讓我好好感謝</li>
                        <li>
                            <a href="https://docs.google.com/spreadsheets/d/1TjnjalZ_7pMc8rbbINDAwAyPbCDrVy49r3A2YDErcHI/edit?usp=sharing"
                                target="_blank" rel="noreferrer">
                                這裡可以看歷史的斗內贈禮名單
                            </a>
                        </li>
                        <li>
                            <a href="https://payment.ecpay.com.tw/Broadcaster/Donate/666577222BF8531D4175DD355DC6FAD7"
                                target="_blank" rel="noreferrer">
                                【綠界科技】
                            </a>
                            |
                            <a href="https://www.paypal.com/paypalme/XiaoJean?country.x=TW&locale.x=zh_TW" target="_blank" rel="noreferrer">
                                【PAYPAL】
                            </a> |
                            <a href="https://payment.opay.tw/Broadcaster/Donate/658E154B04738822369BC4C46E432F3D" target="_blank" rel="noreferrer">
                                【歐富寶】
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div id="cheapserver">
                <img src={require('../assets/小靜素材庫/E.無分類/012-cheapsever.png')} alt="Lost" />
                <div>
                    <p>
                        這是我們會員麥塊伺服器的贊助商，他們是我的朋友，如果大家有需要租伺服器遊玩的話不妨去參考看看，有不同等級主機可以選擇，有問題也可以在他們的
                        <a href="https://discord.com/invite/cheapserver" target="_blank" rel="noreferrer">
                            dc群
                        </a>
                        詢問，使用我的優惠碼 xiaojean
                        還能打95折，希望大家支持他們一下🥰
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Home;