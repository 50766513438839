import React from "react"
import './css/header.css'
import { useState } from "react";
import { Link } from 'react-router-dom'; // Link套件
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowUp } from '@fortawesome/free-solid-svg-icons';


function Header() {
    const [menuState, setmenuState] = useState(true)
    function toggleMenu() {
        if (window.innerWidth < 1024) {
            var headerbody = document.getElementById("header-body")
            var navul = document.getElementById("nav-ul")
            var navlink = document.getElementById("nav-link")
            // var Gotop = document.getElementById("Gototop")
            var burgerMenu = document.getElementById("burger-menu")
            burgerMenu.classList.toggle('menu-open')
            if (menuState) {
                // Open Menu
                headerbody.style.backgroundColor = "#143753"
                headerbody.style.height = "750px"
                navul.style.display = "flex"
                navlink.style.display = "flex"
                // Gotop.style.display = "none"
            } else {
                // Close Menu
                headerbody.style.backgroundColor = "rgba(0, 0, 0, 0.8)"
                headerbody.style.height = "100px"
                navul.style.display = "none"
                navlink.style.display = "none"
                // Gotop.style.display = "block"
            }
            setmenuState(!menuState)
        }
    }
    // nav點擊回饋+關閉漢堡選單
    const [ActiveId, setActiveId] = useState('');
    const changeActiveId = (id) => {
        setActiveId(id);
        toggleMenu();
    }


    return (
        <div id='header-body'>
            {/* LOGO */}
            <div id="div-logo">
                <Link to="/" id="header-logolink" >
                    <img src={require('../../assets/小靜素材庫/E.無分類/004-小靜.png')} alt="Lost" />
                    <p id="logo-text">小靜しずか</p>
                </Link>
                <div id="burger-menu" onClick={toggleMenu}>
                    <div>{(menuState === true) ? "☰" : "✖︎"}</div>
                </div>

            </div>
            {/* 導覽列 */}
            <div id="div-nav">
                <ul id="nav-ul" type='none'>
                    <li><Link className={ActiveId === "home" ? "active-li" : ""} onClick={() => changeActiveId('home')} to="/" id="header-link">首頁</Link></li>
                    <li><Link className={ActiveId === "merch" ? "active-li" : ""} onClick={() => changeActiveId('merch')} to="/Merch" id="header-link">周邊</Link></li>
                    <li><Link className={ActiveId === "about" ? "active-li" : ""} onClick={() => changeActiveId('about')} to="/About" id="header-link">關於我</Link></li>
                    <li><Link className={ActiveId === "rule" ? "active-li" : ""} onClick={() => changeActiveId('rule')} to="/Rule" id="header-link">粉絲守則</Link></li>
                    {/* <li><Link to="/Commision" id="header-link">繪圖委託</Link></li> */}
                </ul>
                <ul id="nav-link" type="none">
                    <li>
                        <a href="https://discord.gg/wyCzz4MJnh" target="_blank" rel="noreferrer">
                            <img src={require('../../assets/小靜素材庫/A.社群網站的icon/Discord-white.png')} alt="Lost" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/xiaojean0128/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0" target="_blank" rel="noreferrer">
                            <img src={require('../../assets/小靜素材庫/A.社群網站的icon/Facebook-white.png')} alt="Lost" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/xiaojean.0128?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noreferrer">
                            <img src={require('../../assets/小靜素材庫/A.社群網站的icon/Instagram-white.png')} alt="Lost" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.twitch.tv/xiaojean0128" target="_blank" rel="noreferrer">
                            <img src={require('../../assets/小靜素材庫/A.社群網站的icon/Twitch-white.png')} alt="Lost" />
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/xiaojean0128" target="_blank" rel="noreferrer">
                            <img src={require('../../assets/小靜素材庫/A.社群網站的icon/Twitter-white.png')} alt="Lost" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/@xiaojean0128" target="_blank" rel="noreferrer">
                            <img src={require('../../assets/小靜素材庫/A.社群網站的icon/Youtube-white.png')} alt="Lost" />
                        </a>
                    </li>
                    <li>
                        <a href="https://reurl.cc/zrE8L7" target="_blank" rel="noreferrer">
                            <img src={require('../../assets/小靜素材庫/A.社群網站的icon/Marshmallow-white.png')} alt="Lost" />
                        </a>
                    </li>
                </ul>
            </div>
            {/* <div id="Gototop" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <FontAwesomeIcon icon={faArrowUp} color="#143753" />
            </div> */}
        </div >
    );
}

export default Header;
